<template>
  <div v-if="user">
    <v-row>
      <v-col>
        <v-card elevation="3" shaped>
          <v-card-title>Informasi User</v-card-title>
          <v-card-subtitle>Informasi umum tentang user</v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <h1 class="subtitle-1">First Name</h1>
                <p class="title black--text">{{ user.firstName }}</p>
                <h1 class="subtitle-1">Last Name</h1>
                <p class="title black--text">{{ user.lastName }}</p>
                <h1 class="subtitle-1">Username</h1>
                <p class="title black--text">{{ user.username || "-" }}</p>
                <h1 class="subtitle-1">Email</h1>
                <p class="title black--text">{{ user.email || "-" }}</p>
              </v-col>
              <v-col>
                <h1 class="subtitle-1">Created At</h1>
                <p class="title black--text">
                  {{ localDate(user.createdDate) }}
                </p>
                <h1 class="subtitle-1">Created By</h1>
                <p class="title black--text">
                  {{ user.userNameCreate }}
                </p>
                <h1 class="subtitle-1">Updated At</h1>
                <p class="title black--text">
                  {{ localDate(user.updatedDate) }}
                </p>
                <h1 class="subtitle-1">Updated By</h1>
                <p class="title black--text">
                  {{ user.userNameUpdate }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    localDate(date) {
      date = new Date(date);
      return Intl.DateTimeFormat("id-ID", { dateStyle: "long" }).format(date);
    },
  },
};
</script>
